import React, { useEffect, useState } from 'react';
import './GerarCupons.css';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function GerarCupons() {
  const [creditos, setCreditos] = useState(0);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cuponsGerados, setCuponsGerados] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      console.log('Buscando dados do usuário...');
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'clientes', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log('Dados do usuário:', userData);
            setCreditos(userData.creditos || 0);
            setNome(userData.nome);
            setEmail(userData.email);
          } else {
            console.log('Documento do usuário não encontrado.');
          }
        } catch (error) {
          console.error('Erro ao buscar dados do usuário:', error);
        }
      } else {
        console.log('Usuário não autenticado.');
      }
    };

    fetchUserData();
  }, []);

  const generateRandomCoupon = async () => {
    console.log('Iniciando geração de cupons...');
    const user = auth.currentUser;
    if (!user || creditos <= 0) {
      console.log('Usuário não autenticado ou sem créditos!');
      return;
    }

    const userDocRef = doc(db, 'clientes', user.uid);
    const generatedCoupons = [];
    const maxCoupons = creditos;

    try {
      for (let i = 0; i < maxCoupons; i++) {
        let coupon;
        let attempts = 0;
        const maxAttempts = 100;

        while (attempts < maxAttempts) {
          coupon = '00' + Math.floor(Math.random() * 100000).toString().padStart(5, '0');

          const couponDocRef = doc(db, 'cupons', coupon);
          const couponDoc = await getDoc(couponDocRef);

          if (!couponDoc.exists()) {
            console.log(`Cupom ${coupon} gerado com sucesso.`);
            await setDoc(couponDocRef, {
              numero: coupon,
              nome: nome,
              email: email,
              data: new Date().toISOString(),
            });

            generatedCoupons.push(coupon);
            break;
          } else {
            console.log(`Cupom ${coupon} já existe. Tentando outro...`);
          }

          attempts++;
        }

        if (attempts === maxAttempts) {
          console.log('Limite de tentativas atingido para geração de um cupom único.');
          break;
        }
      }

      if (generatedCoupons.length > 0) {
        console.log('Cupons gerados:', generatedCoupons);

        // Atualiza os dados do usuário
        await updateDoc(userDocRef, {
          creditos: 0, // Zera os créditos após a geração
          historicoCreditos: increment(maxCoupons),
          cupons: increment(generatedCoupons.length),
        });

        // Atualiza o estado para exibir o modal
        setCuponsGerados(generatedCoupons);
        setShowModal(true);
      } else {
        console.log('Nenhum cupom foi gerado. Tente novamente mais tarde.');
      }
    } catch (error) {
      console.error('Erro durante a geração de cupons:', error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload(); // Atualiza a página ao fechar o modal
  };

  return (
    <div className="gerar-cupons-container">
      <button onClick={() => navigate('/inicio')} className="voltar-button">
        <FontAwesomeIcon icon={faArrowLeft} /> Voltar para Início
      </button>
      <div className="content-box">
        <h1>Gerar Cupons</h1>
        <div className="info-container">
          <div className="info-box">
            <p className="highlight">
              Você possui <strong>{creditos}</strong> crédito(s).
            </p>
            <p>A cada R$100 abastecidos, você ganha um crédito.</p>
            <p>
              No momento, você tem <strong>{creditos}</strong> crédito(s) para
              gerar cupons.
            </p>
            <p className="bold-text">Cada cupom é uma chance de ganhar!</p>
          </div>
        </div>
        <button onClick={generateRandomCoupon} className="gerar-cupons-button">
          <FontAwesomeIcon icon={faTicketAlt} /> Gerar Cupons
        </button>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <h2>Cupons Gerados</h2>
              <div className="cupons-lista">
                {cuponsGerados.map((cupom, index) => (
                  <p key={index} className="cupom-gerado">
                    <strong>{cupom}</strong>
                  </p>
                ))}
              </div>
              <p>Boa sorte!</p>
              <button onClick={handleModalClose} className="modal-button">
                Fechar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GerarCupons;




